import { useState, useRef, useEffect } from 'react'
import AgoraRTC from "agora-rtc-sdk-ng"
import { GlobalProvider, useClient, useStart, useUsers, useSpeaking } from './GlobalContext';
import imgMuteVideo from '../src/assets/images/video-mute.png';
import imgUnmuteVideo from '../src/assets/images/video-unmuted.png';
import imgMuteAudio from '../src/assets/images/audio-mute.png';
import imgUnmuteAudio from '../src/assets/images/audio-unmuted.png';
import imgCallEnd from '../src/assets/images/callEnd.png';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SwitchCameraIcon from '../src/assets/images/cameraFull.png';
var objCodec = require("object-encode");

const App = () => {


  return (
    <GlobalProvider>
      <Content />
    </GlobalProvider>
  );
}

const Content = () => {
  const setUsers = useUsers()[1]
  const [start, setStart] = useStart()
  const rtc = useClient()
  const [camData,setCamData] = useState()
  const [camstatus,setCamStatus] = useState(false)

  const cameraSwitch = (val) =>{
    setCamStatus(val)
  }


  const [open, setOpen] = useState(false);

  const handleClickOpen  = () => {
    setOpen(true);
  };

  const handleDialog = () => {
    setOpen(false);
  }

  const handleClose = () => {
    window.close();

  };
  const handleReload = () => {
    window.location.reload();
  }
  

  const modifyGain = (stream, gainValue) => {
    var ctx = new AudioContext();
    var src = ctx.createMediaStreamSource(stream);
    var dst = ctx.createMediaStreamDestination();
    var gainNode = ctx.createGain();
    gainNode.gain.value = gainValue;
    [src, gainNode, dst].reduce((a, b) => a && a.connect(b));
    return dst.stream;
  };

  let init = async (name, appId, token, width, height) => {
    rtc.current.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    initClientEvents()
    const uid = await rtc.current.client.join(appId, name, token, null);
    // Create an audio track from the audio sampled by a microphone.
    let option = true
    if (option === true) {
      //You can manipulate the audioTrack here
      const media = await navigator.mediaDevices.getUserMedia({ video: false, audio: true })
      let audioStream = modifyGain(media, 100)
      let audioTrack = audioStream.getAudioTracks()[0]
      rtc.current.localAudioTrack = AgoraRTC.createCustomAudioTrack({ mediaStreamTrack: audioTrack });
    }
    else {
      //Agora provided audio manipulation
      rtc.current.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({
        encoderConfig: {
          sampleRate: 48000,
          stereo: true,
          bitrate: 128,
        },
      })
      // rtc.current.localAudioTrack.setVolume(10)
    }


    // Get all audio and video devices.
    AgoraRTC.getDevices().then(async (devices) => {
      const audioDevices = devices.filter(function (device) {
        return device.kind === "audioinput";
      });
      const videoDevices = devices.filter(function (device) {
        return device.kind === "videoinput";
      });

    
      setCamData(videoDevices)
      // setCamStatus(false)
      
      // var selectedMicrophoneId = audioDevices[0].deviceId;
      var selectedCameraId = videoDevices[0].deviceId;
      rtc.current.localVideoTrack = await AgoraRTC.createCameraVideoTrack(
        {
          encoderConfig: {
            width: width,
            deviceId:selectedCameraId,
            // Specify a value range and an ideal value
            height: height,
            frameRate: 15,
            bitrateMin: 600, bitrateMax: 1000,
            orientationMode: "ORIENTATION_MODE_ADAPTIVE",
          }
        }
      );

      setUsers((prevUsers) => {
        return [...prevUsers, { uid: uid, audio: true, video: true, client: true, videoTrack: rtc.current.localVideoTrack, audioTrack: rtc.current.localAudioTrack, userType: "local" }]
      })
      //Publishing your Streams
      await rtc.current.client.publish([rtc.current.localAudioTrack, rtc.current.localVideoTrack]);
      setStart(true)

    })

    
    // Create a video track from the video captured by a camera.
    // rtc.current.localVideoTrack = await AgoraRTC.createCameraVideoTrack(
    //   {
    //     encoderConfig: {
    //       width: width,
    //       // Specify a value range and an ideal value
    //       height: height,
    //       frameRate: 15,
    //       bitrateMin: 600, bitrateMax: 1000,
    //       orientationMode: "ORIENTATION_MODE_ADAPTIVE",
    //     }
    //   }
    // );
    //Adding a User to the Users State
    // setUsers((prevUsers) => {
    //   return [...prevUsers, { uid: uid, audio: true, video: true, client: true, videoTrack: rtc.current.localVideoTrack, audioTrack: rtc.current.localAudioTrack, userType: "local" }]
    // })
    // //Publishing your Streams
    // await rtc.current.client.publish([rtc.current.localAudioTrack, rtc.current.localVideoTrack]);
    // setStart(true)
    // rtc.current.localVideoTrack.setEncoderConfiguration({ width: 640, height: 360 }).then(() => { /** ... **/ })
  }

  const initClientEvents = () => {
   
    rtc.current.client.on("user-joined", async (user) => {
      // New User Enters
      await setUsers((prevUsers) => {
        return [...prevUsers, { uid: user.uid, audio: user.hasAudio, video: user.hasVideo, client: false, userType: "remote" }]
      })
    });


    rtc.current.client.on("user-published", async (user, mediaType) => {

      await rtc.current.client.subscribe(user, mediaType);
      //Close the dialog box when remote user join again.
      handleDialog();
      if (mediaType === "video") {
        const remoteVideoTrack = user.videoTrack;
        await setUsers((prevUsers) => {
          return (prevUsers.map((User) => {
            if (User.uid === user.uid) {

              return { ...User, video: user.hasVideo, videoTrack: remoteVideoTrack, userType: "remote" }
            }
            return User
          }))

        })
      }

      if (mediaType === "audio") {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack.play();
        await setUsers((prevUsers) => {
          return (prevUsers.map((User) => {
            if (User.uid === user.uid) {
              return { ...User, audio: user.hasAudio, audioTrack: remoteAudioTrack, userType: "remote" }
            }
            return User
          }))

        })
      }
    });

    rtc.current.client.on("user-unpublished", (user, type) => {
      if (type === 'audio') {
        setUsers(prevUsers => {
          return (prevUsers.map((User) => {
            if (User.uid === user.uid) {
              return { ...User, audio: !User.audio }
            }
            return User
          }))
        })
      }
      if (type === 'video') {
        setUsers(prevUsers => {
          return (prevUsers.map((User) => {
            if (User.uid === user.uid) {
              return { ...User, video: !User.video }
            }
            return User
          }))
        })
      }
    });


    rtc.current.client.on("user-left", (user) => {
      //User Leaves
      setUsers((prevUsers) => {
        return prevUsers.filter(User => User.uid !== user.uid)
      })
      handleClickOpen();
    });
  }

  useEffect(() => {
    let pathname = "";
    if (window.location.pathname.length > 2) {
      pathname = window.location.pathname.substring(1);
      var salt = process.env.REACT_APP_DECODE_TOKEN;
      var values = objCodec.decode_object(pathname, "base64", salt);
      if (values.setAppId && values.setChannel && values.setToken) {
        let width = window.screen.availWidth;
        let height = window.screen.availHeight;
        try{
          if(width/height >= 1){
            init(values.setChannel, values.setAppId, values.setToken, 1280, 720);
          }else{
            init(values.setChannel, values.setAppId, values.setToken, 480, 360);
          }
        }catch(err){
          console.log("ERROR ",err)
        }
        
        // if (window.screen.availWidth > 767) {
        //   init(values.setChannel, values.setAppId, values.setToken, 1280, 720);
        // } else {
        //   init(values.setChannel, values.setAppId, values.setToken, 480, 720);
        // }

      } else {
        alert("invalid link token please try again");
      }
    } else {
      console.log("path not found");
    }

  }, []);
  return (
    <>
      <div className='container'>
        <div className="App">
          {start && <Videos camData={camData} camstatus={camstatus} cameraSwitch={cameraSwitch}/>}
          <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Call Ended"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Patient has left the call.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className="close" onClick={handleClose}>Close</Button>
              <Button className="close" onClick={handleReload} >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
          {/* {<ChannelForm initFunc={init} />} */}
        </div>
      </div>
    </>

  )
}


const Videos = ({camData,camstatus,cameraSwitch}) => {
 
  const users = useUsers()[0]
  return (

    // <div id='videos'>
    //   {users.length && users.map((user) => <Video key={user.uid} user={user} />)}
    // </div>
    <>
      {/* <div className="first" style={{position:"relative",width:"540px",height:"600px",paddingLeft:"50px"}}>
        {<Video key={users[0].uid} user={users[0]} />}
      </div> */}
      {users.length > 0 && users[0].userType == "local" ?
        <div className="first" style={{ position: "relative", width: "100%", height: "100%", paddingLeft: "50px" }}>
          {users[0] && <Video cameraSwitch={cameraSwitch} camstatus={camstatus} camData={camData} typeCheck="local" key={users[0].uid} user={users[0]} />}
        </div>
        :
        <div className="first" style={{ position: "relative", width: "100%", height: "100%", paddingLeft: "50px" }}>
          {users[1] && <Video cameraSwitch={cameraSwitch} camstatus={camstatus} camData={camData} typeCheck="local" key={users[1].uid} user={users[1]} />}
        </div>}
      {users.length > 0 && users[0].userType == "remote" ?
        <div className="second" style={{ position: "relative", width: "100%", height: "100%", paddingLeft: "200px", borderRadius: "30px" }}>
          {users[0] && <Video typeCheck="remote" key={users[0].uid} user={users[0]} />}
          { }
        </div>
        :
        <div className="second" style={{ position: "relative", width: "100%", height: "100%", paddingLeft: "200px", borderRadius: "30px" }}>
          {users[1] && <Video typeCheck="remote" key={users[1].uid} user={users[1]} />}
          { }
        </div>}





    </>
  )

}

export const Video = ({ user, typeCheck,camData,camstatus,cameraSwitch}) => {
  const vidDiv = useRef(null)

  const playVideo = () => {
    if (user.videoTrack) {
      user.videoTrack.play(vidDiv.current)
    }
  }

  const stopVideo = () => {
    if (user.videoTrack) {
      user.videoTrack.stop()
    }
  }

  useEffect(() => {
    playVideo()
    return () => {
      stopVideo()
    }
    // eslint-disable-next-line
  }, [user.videoTrack])

  return (
    <div className='vid' ref={vidDiv} >
      <Controls cameraSwitch={cameraSwitch} camData={camData} camstatus={camstatus} typeCheck={typeCheck} user={user} />
    </div>
  )
}


export const Controls = ({ user, typeCheck,camData,camstatus,cameraSwitch }) => {

  const setStart = useStart()[1]
  const setUsers = useUsers()[1]
  const rtc = useClient()
  const [speaking, setSpeaking] = useSpeaking()

  const switchBtn = async () => {
    // cameraSwitch()
    if(camstatus){
      await rtc.current.localVideoTrack.setDevice(camData[0].deviceId).then(()=>{
        cameraSwitch(false)
      })
      
    }else{
      await rtc.current.localVideoTrack.setDevice(camData[1].deviceId).then(()=>{
        cameraSwitch(true)
      })
      
    }
   
   
    // rtc.current.localVideoTrack.getVideoTrack().stop();
    // rtc.current.localVideoTrack.switchDevice("video", "03f55ca8b01df3146580061fbf7421e93aa59bbf377159cae094b277cb6050d0");
  }
  const leaveChannel = async () => {
    // Destroy the local audio and video tracks.
    await rtc.current.localAudioTrack.close();
    await rtc.current.localVideoTrack.close();
    await rtc.current.client.leave();
    setUsers([])
    clearInterval(rtc.current.checkAudio)
    setStart(false)
    // window.close();
    window.top.close();
  }

  useEffect(() => {
    const startAudioCheck = async () => {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true })
      const audioContext = new AudioContext();
      const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(mediaStream);
      const analyserNode = audioContext.createAnalyser();
      mediaStreamAudioSourceNode.connect(analyserNode);
      const pcmData = new Float32Array(analyserNode.fftSize);

      const checkAudio = () => {
        analyserNode.getFloatTimeDomainData(pcmData);
        let sumSquares = 0.0;
        for (const amplitude of pcmData) { sumSquares += amplitude * amplitude; }
        let vol = Math.sqrt(sumSquares / pcmData.length)
        if (vol > 0.05 && !speaking) {
          setSpeaking(true)
          setTimeout(() => { setSpeaking(false) }, 2000)
        }
      };

      if (user.audio === false) {
        rtc.current.checkSpeakingInterval = setInterval(checkAudio, 100)
      }
      else {
        clearInterval(rtc.current.checkSpeakingInterval)
      }
    }
    if (user.client) {
      startAudioCheck()
    }
    return () => {
      // eslint-disable-next-line
      clearInterval(rtc.current.checkSpeakingInterval)
    }
    // eslint-disable-next-line
  }, [user.audio])

  const mute = (type, id) => {
    if (type === 'audio') {
      setUsers(prevUsers => {
        return (prevUsers.map((user) => {
          if (user.uid === id) {
            user.client && rtc.current.localAudioTrack.setMuted(user.audio)
            return { ...user, audio: !user.audio }
          }
          return user
        }))
      })
    }
    else if (type === 'video') {
      setUsers(prevUsers => {
        return prevUsers.map((user) => {
          if (user.uid === id) {
            user.client && rtc.current.localVideoTrack.setEnabled(!user.video)
            return { ...user, video: !user.video }
          }
          return user
        })
      })
    }
  }


  return (
    <>
      {typeCheck == "local" &&
        <div className='controls'>
          {window.screen.availWidth < 767 && <p onClick={() => switchBtn()}>{<img src={SwitchCameraIcon} />}</p>}
          {/* Button to Mute/Unmute Mic */}
          {<p onClick={() => user.client && mute('audio', user.uid)}>{!user.audio ? <img src={imgMuteAudio} /> : <img src={imgUnmuteAudio} />}</p>}
          {/* Button to Mute/Unmute Video */}
          {<p onClick={() => user.client && mute('video', user.uid)}>{user.video ? <img src={imgMuteVideo} /> : <img src={imgUnmuteVideo} />}</p>}
          {/* {<input type="number" placeholder="Volume" onChange={(e) => { let vol = parseInt(e.target.value); !isNaN(vol) && vol >= 0 && vol <= 1000 && (user.audioTrack.setVolume(parseInt(e.target.value))) }} />} */}
          {user.client && <p onClick={() => leaveChannel()}>{<img src={imgCallEnd} />}</p>}

          
          {/* Button to quit call if client */}
        </div>
      }

      {typeCheck == "remote" &&
        <div className='controls remote'>

          <>
            {<p>{!user.audio && <img src={imgMuteAudio} />}</p>}
            {<p>{!user.video && <img src={imgUnmuteVideo} />}</p>}
          </>

          {/* {<p onClick={() => user.client && mute('audio', user.uid)}>{!user.audio && <img src={imgMuteAudio} />}</p>}
          {<p onClick={() => user.client && mute('video', user.uid)}>{!user.video && <img src={imgUnmuteVideo} />}</p>}
          {user.client && <p onClick={() => leaveChannel()}>{<img src={imgCallEnd} />}</p>} */}
        </div>
      }
    </>

  )
}


export default App;


